'use client';

import { Locale } from '@core/Entities/Locale/Locale.entity';

import styles from './Loading.module.css';
import { LOADING_TAGS } from './Loading.constants';
import { memo } from 'react';

type Props = {
  locale?: Locale;
};

export const Loading = memo(({ locale = 'uk' }: Props) => {
  const tags = LOADING_TAGS[locale];
  const randomTag = tags?.length ? tags[Math.floor(Math.random() * tags.length)] : undefined;

  return (
    <div className={styles.wrapper}>
      {randomTag && <div className={styles.tag}>{randomTag}</div>}
      <div className={styles.loader}>
        {[...Array(5)].map((_, index) => (
          <div key={index} className={styles.dot} style={{ '--index': `${index}` }} />
        ))}
      </div>
    </div>
  );
});
