export const LOADING_TAGS = {
  uk: [
    'Revving up',
    'Accelerating to awesome',
    'Cruising through code',
    'Shining our hubcaps',
    'Adjusting the rearview mirror',
    'Buckle up for browsing',
    'Taking a quick pit stop',
    'Inflating the tires',
    'Turning on the headlights',
    'Warming up the engine',
    'Parking into place',
    'Calibrating the car catalog',
    'Navigating the net',
    'Engaging the engine',
    'Aligning the autos',
    'Buffing the browser',
    'Shifting into shopping mode',
    'Gearing up the gallery',
    'Testing the turn signals',
    'Checking the chassis',
    'Assembling the autos',
    'Loading luxury lineups',
    'Configuring the car cam',
  ],
  fr: [],
};
